//Import each of the mrare js files
require('./background-images');
require('./countdown');
require('./flickity');
require('./isotope');
require('./maps');
require('./prism');
require('./smooth-scroll');
require('./sticky');
require('./util');
require('./video');
require('./wizard');
